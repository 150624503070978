import React, { useState } from 'react';
import AuthService from './AuthService';

import { useUser } from './AuthContext';

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useUser();
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    AuthService.login(username, password, setUser).then(
      () => {
        // Navigate to profile or home page on successful login
        console.log("Logged in successfully");
        setErrorMessage("");
      },
      error => {
        console.log("Login failed");
        const message = error.response?.data?.error || "Login failed. Please try again.";
        setErrorMessage(message);
      }
    );
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <label>Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Sign In</button>
      </form>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
  );
};

export default SignIn;
