import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PRICE_VARIATION_API_URL } from './constants';


const PriceVariatioinDashboard = () => {
    const [resp, setResp] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const token = localStorage.getItem('token');

    // Use localStorage to get the initial intervalValue or default to '15'
    const [intervalValue, setIntervalValue] = useState(() => {
        // Attempt to get the value from localStorage, or default to '15'
        const savedIntervalValue = localStorage.getItem('intervalValue');
        return savedIntervalValue || '15';
    });

    const formattedDateTime = resp.last_interval_update_time
        ? new Date(resp.last_interval_update_time).toLocaleString()
        : "";

    // Function to fetch data
    const fetchResp = (flagValue) => {
        setIsLoading(true);
        // Include the flag value in the API call. This example assumes you can append it as a query parameter.
        axios.get(`${PRICE_VARIATION_API_URL}?flag=${flagValue}&interval=${intervalValue}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        })
            .then(response => {
                console.log(response.data, 'fetchResp called with flag:', flagValue);
                setResp(response.data);
                setData(response.data.data)
            })
            .catch(error => console.error('There was an error!', error))
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the outcome
            });
    };

    // Utility function to format seconds into hours and minutes
    const formatTime = (seconds) => {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        let timeString = '';
        if (days > 0) {
            timeString += `${days}d `;
        }
        timeString += `${hours}h ${minutes}m`;
        return timeString;
    };



    // Store intervalValue in localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('intervalValue', intervalValue);
    }, [intervalValue]);

    // Fetch initial data
    useEffect(() => {
        fetchResp(1); // Default value or initial load with flag=1
    }, [intervalValue]);

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });

        if (direction === 'descending') {
            sortedData.reverse();
        }

        setData(sortedData);
    };

    const handleDropdownChange = (e) => {
        console.log('tare', e.target.value);
        setIntervalValue(e.target.value);
    };


    return (
        <div>
            <div>
                <button onClick={() => fetchResp(2)}>Load Data (flag=2)</button>
                <button onClick={() => fetchResp(3)}>Load Data (flag=3)</button>
            </div>
            <div>
                <select value={intervalValue} onChange={handleDropdownChange}>
                    <option value="15">1 hour</option>
                    <option value="60">4 hour</option>
                    <option value="240">day</option>
                    <option value="D">Week</option>
                    <option value="W">Month</option>
                </select>
                <span style={{ marginLeft: '100px' }}>last_interval_update_time: {formattedDateTime}</span>
            </div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>symbol</th>
                            <th>cur_price</th>
                            <th>lowest_price</th>
                            <th>highest_price</th>
                            <th onClick={() => sortData('from_lowest')}>from_lowest</th>
                            <th onClick={() => sortData('from_highest')}>from_highest</th>
                            <th onClick={() => sortData('highest_ago')}>highest_ago</th>
                            <th onClick={() => sortData('lowest_ago')}>lowest_ago</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.symbol}</td>
                                <td>{item.cur_price}</td>
                                <td>{item.lowest_price}</td>
                                <td>{item.highest_price}</td>
                                <td>{item.from_lowest}</td>
                                <td>{item.from_highest}</td>
                                <td>{formatTime(item.highest_ago)}</td>
                                <td>{formatTime(item.lowest_ago)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>


    );

}
export default PriceVariatioinDashboard;