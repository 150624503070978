import React from 'react';
import { Link } from 'react-router-dom';
import AuthService from './AuthService';
import { useUser } from './AuthContext';
import { useNavigate } from 'react-router-dom';


// Navigation component
const Navigation = () => {
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    return (
        <nav>
            <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', justifyContent: 'space-around', backgroundColor: '#333' }}>
                <li><Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link></li>
                <li><Link to="/price-variation" style={{ color: 'white', textDecoration: 'none' }}>Price Variation</Link></li>
                {/* Conditional rendering based on user login status */}
                {user?.username ? (
                    <li style={{ color: 'white' }}>Welcome, {user.username}! <button onClick={() => { AuthService.logout(setUser);  navigate('/signin');  }} style={{ color: 'white', background: 'none', border: 'none', cursor: 'pointer' }}>Logout</button></li>
                ) : (
                    <>
                        <li><Link to="/signin" style={{ color: 'white', textDecoration: 'none' }}>Sign In</Link></li>
                    </>
                )}
            </ul>
        </nav>
    );
};
export default Navigation;