// Dashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, UPDATE_PRICE_API_URL } from './constants';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch data from the Django REST API
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('token not exist')
      navigate('/signin');
      return;
    }

    setIsLoading(true);
    axios.get(API_URL, {
      headers: {
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      }
    })
      .then(response => {
        console.log(response.data, 'use effect called');
        setData(response.data.data);
      })
      .catch(error => console.error('There was an error!, in use effect', error))
      .finally(() => {
        setIsLoading(false); // Stop loading regardless of the outcome
      });
  }, [navigate]);



  // Submit the updated data to the Django REST API
  const handleSubmit = () => {
    const isValid = data.every(item => /^\d*\.?\d+$/.test(item.quantity));
    const token = localStorage.getItem('token');
    
    if (isValid) {
      setIsLoading(true);
      // Proceed with submission
      console.log('Data is valid, proceed with API submission.');
      axios.post(API_URL, data, {
        headers: {
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        }
      })
        .then(response => {
          // Assuming response.data contains the updated data array
          const updatedData = response.data.data.map(item => ({
            ...item,
            hasChanged: false // Reset the hasChanged flag for all items
          }));
          setData(updatedData); // Update the state with the new data
          console.log('Submission successful', response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading regardless of the outcome
        });
    } else {
      // Handle invalid data
      console.error('Data validation failed, cannot proceed.');
    }

  };

  const handleQuantityChange = (index, newValue) => {
    const decimalRegex = /^\d*\.?\d*$/;

    if ((decimalRegex.test(newValue) || newValue === '') && newValue !== data[index].initialQuantity) {
      const newData = [...data];
      const newQuantity = newValue === '' ? 0 : parseFloat(newValue);
      newData[index].quantity = newValue;
      newData[index].hasChanged = newValue !== String(newData[index].initialQuantity);
      newData[index].total = newData[index].price * newQuantity;
      setData(newData);
    }
  };

  const updatePrices = () => {
    setIsLoading(true);
    // Assuming '/api/update-prices' is the endpoint that updates and returns the latest prices
    axios.post(UPDATE_PRICE_API_URL)
      .then(response => {
        const updatedData = response.data.data.map(item => ({
          ...item,
          hasChanged: false, // Reset the hasChanged flag if needed
        }));
        setData(updatedData); // Update the state with the new, updated data
        console.log('Prices updated successfully');
      })
      .catch(error => {
        console.error('Failed to update prices:', error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading regardless of the outcome
      });
  };



  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.symbol}</td>
                <td>{item.price}</td>
                <td>
                  <input
                    type="text"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    className={item.hasChanged ? 'quantity-changed' : ''}
                  />
                </td>
                <td>
                  {new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(item.total)}
                </td>


              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="3">Sum of Totals</th>
              <th>
                {new Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).format(data.reduce((acc, item) => acc + parseFloat(item.total), 0))}
              </th>


            </tr>
          </tfoot>
        </table>
      )}
      <button onClick={handleSubmit}>Submit</button>
      <button onClick={updatePrices}>Update Prices</button>

    </div>
  );
};

export default Dashboard;
