import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import for React Router v6
import AuthService from './AuthService'; // Ensure this path is correct

const SignUp = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate, replacing useHistory
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setErrorMessage('');

    try {
      await AuthService.signup(username, password, email);
      setLoading(false); // Stop loading
      // Reset form fields
      setUsername('');
      setEmail('');
      setPassword('');
      // Redirect the user to the login page after successful signup
      navigate('/signin'); // Using useNavigate for redirection in React Router v6
    } catch (error) {
      setLoading(false); // Stop loading on error
      setErrorMessage(error.response.data.message || 'Failed to sign up. Please try again.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSignUp}>
        <h2>Sign Up</h2>
        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
        <div>
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
    </div>
  );
};

export default SignUp;
