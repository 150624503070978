import './App.css';
import Dashboard from './Dashboard';
import PriceVariatioinDashboard from './PriceVariatioinDashboard';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';
import { UserProvider } from './AuthContext';
import Navigation from './Navigation';

import { useNavigate } from 'react-router-dom';


function App() {
  const navigate = useNavigate();

  return (

    <UserProvider navigate={navigate}>

      <div>
        <Navigation />
        <Routes>
          <Route path="/price-variation" element={<PriceVariatioinDashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </div>

    </UserProvider>
  );
}

export default App;
