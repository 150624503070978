import axios from 'axios';
import { SIGNIN_URL, SIGNUP_URL } from './constants';

class AuthService {
    async login(username, password, setUser) {
        

        try {
            const response = await axios.post(SIGNIN_URL, { username, password });
            console.log(`responase data: ${response.data}`);
            const { token, username: responseUsername } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('username', responseUsername);
            setUser({ username: responseUsername, token: token });
            return response.data;
        } catch (error) {
            console.log(`error: ${error}`)
            // Handle errors, e.g., incorrect login credentials or server errors
            throw error;
        }
    }

    async signup(username, password, email) {
        try {
            // Assuming the API expects username, password, and email for signup
            // Adjust according to your API's expected parameters
            const response = await axios.post(SIGNUP_URL, { username, password, email });
            // Optionally, you might want to automatically log the user in after signup
            // If the API response includes user details or access tokens, store them as needed
            // if (response.data.token) {
            //     localStorage.setItem('user', JSON.stringify(response.data));
            // }
            return response.data;
        } catch (error) {
            // Handle signup errors, e.g., user already exists, invalid data
            throw error;
        }
    }

    logout(setUser) {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setUser({ username: null, token: null });
    }

    getCurrentUsername() {
        return JSON.parse(localStorage.getItem('username'));
    }

    getBearerToken() {
        const token = localStorage.getItem('token');
        if (!token){
            return null;
        }
        return `Bearer ${token}`
    }

}

export default new AuthService();
