import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import AuthService from './AuthService'; // Adjust the import path as needed

export const UserContext = createContext();

export const UserProvider = ({ children, navigate }) => {
  
  const [user, setUser] = useState({
    username: localStorage.getItem('username'),
    token: localStorage.getItem('token')
  });

  useEffect(() => {
    const setupInterceptors = () => {
      axios.interceptors.response.use(response => response, error => {
        if (error.response && error.response.status === 403) {
          // If the status code is 401, log out and reset user context
          AuthService.logout(setUser);
          navigate('/signin');
          console.log('still called')
        }
        return Promise.reject(error);
      });
    };

    setupInterceptors();

    return () => {
      // This function is called when the component unmounts
      // It's important to eject the interceptor so it doesn't accumulate
      axios.interceptors.response.eject(setupInterceptors);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to use the user context
export const useUser = () => useContext(UserContext);
