// src/constants.js
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log('API_BASE_URL', API_BASE_URL)
export const API_URL = `${API_BASE_URL}/api/dashboard`;
export const PRICE_VARIATION_API_URL = `${API_BASE_URL}/api/price-variation-dashboard`;
export const UPDATE_PRICE_API_URL = `${API_BASE_URL}/api/update-prices-dashboard`;
export const SIGNIN_URL = `${API_BASE_URL}/api/accounts/signin`;
export const LOGOUT_URL = `${API_BASE_URL}/api/accounts/logout`;
export const SIGNUP_URL = `${API_BASE_URL}/api/accounts/signup`;
export const USER_DETAILS_URL = `${API_BASE_URL}/api/accounts/user-details`;
